import React, {useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import emailjs from "@emailjs/browser";
import logoBlack from '../assets/logo-black.png'; 
import water from '../assets/Water.png'; 
import '../css/ContactUs.css';
import SocialMedia from './SocialMedia';

const ContactUs = () => {
  const { t } = useTranslation();
  const form = useRef();
  const [emailSent, setEmailSent] = useState(false)
  const [loading, setLoading] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm(
        "service_b8c7lhv",
        "template_h7i91ak",
        form.current,
        "LrBidh5_XhVW9itLJ"
      )
      .then(
        (result) => {
          console.log(result.text);
          setEmailSent(true);
          setLoading(false);
        },
        (error) => {
          console.log(error.text);
          setEmailSent(false);
          setLoading(true);
        }
      );
    e.target.reset();
  };

  return (
    <section id="contact-us" className="contact-us">
      <div className="water-image">
        <img src={water} alt="Model" />
      </div>
      <div className="contact-us-content">
        <img src={logoBlack} alt="Logo" className="contact-us-logo" id='contact-us-logo'/>
        <h2>{t('ContactUs.title')}</h2>
        <p>{t('ContactUs.description')}</p>
        <form 
          className="contact-form"
          onSubmit={sendEmail}
          ref={form}
          >
          <input type="text" name="name" autoComplete="true" placeholder={t('ContactUs.name')} />
          <input type="text" name="subject" autoComplete="true" placeholder={t('ContactUs.subject')} />
          <input type="email" name="email" autoComplete="true" placeholder={t('ContactUs.email')} />
          <textarea name="message" placeholder={t('ContactUs.message')} />
          <button type="submit" disabled={loading}>
            {loading ? (
              <div className="spinner"></div>
            ) : (
              t('ContactUs.sendMessage')
            )}
          </button>
        </form>
        {emailSent && <div className="email-sent-message">Thank you for contacting us!</div>}
        {/* <SocialMedia/> */}
      </div>
    </section>
  );
};

export default ContactUs;
