import React from 'react';
import '../css/MainContent.css';
import { useTranslation } from 'react-i18next';
import mainImage from '../assets/ArtQuestMain.png';
import mainImageGerman from '../assets/ArtMainQuestGerman.png';
import mainImageBulgarian from '../assets/ArtMainQuestBulgarian.png';
import downButton from '../assets/DownButton.png';
import textBubble from '../assets/textBubble.png';
import LanguageSwitcher from './LanguageSwitcher';

const MainContent = () => {
  const { t, i18n } = useTranslation();

  const smoothScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  let currentMainImage = mainImage;
  if (i18n.language === 'de') {
    currentMainImage = mainImageGerman;
  } else if (i18n.language === 'bg') {
    currentMainImage = mainImageBulgarian;
  }

  return (
    <section id="main-content" className="main-content">
      <div className="container">
        <img src={currentMainImage} alt="Splash art" />
        <p>{t('MainContent.explore')}</p>
        <button className='down-button' onClick={() => smoothScroll('who-we-are')}><img src={downButton} alt="Down button" /></button>
        <div>
            <span className="language-switcher"><LanguageSwitcher /></span>
            <span className="talk-to-us">
                <button onClick={() => smoothScroll('contact-us-logo')}>
                  <p className='talkToUsText'>{t('MainContent.talkToUs')}<img src={textBubble} alt="Text Bubble"/></p>
                </button>
            </span>
        </div>
      </div>
    </section>
  );
};

export default MainContent;