import React, { useState } from 'react';
import '../css/WhoWeAre.css';
import { useTranslation } from 'react-i18next';
import whoWeAreImage from '../assets/WhoWeAre.png';
import arrowDown from '../assets/arrowdown.png';

const WhoWeAre = () => {
  const { t } = useTranslation();
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => setClick(false);
  const smoothScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
    }
    closeMobileMenu();
  };

  return (
    <section id="who-we-are" className="who-we-are">
      <h2 className='whoWeAreTitle'>{t('WhoWeAre.title')}</h2>
      <div className="container">
        <img src={whoWeAreImage} alt="Representation of the team" />
        <div className="text-content">
          <h2 className='descriptionTitle'>{t('WhoWeAre.descriptionTitle')}</h2>
          <p>{t('WhoWeAre.description')}</p>
          <button className="ourResultsText"onClick={() => smoothScroll('our-work')}>
              {t('results')}                     
              <img
                src={arrowDown}
                alt='Expand'
                className="arrow"
              />
          </button>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;