import React from 'react';
import i18n from 'i18next';
import '../css/LanguageSwitcher.css';

const LanguageSwitcher = () => {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const currentLanguage = i18n.language;

  return (
    <div className="language-switcher">
      <button
        onClick={() => changeLanguage('en')}
        className={currentLanguage === 'en' ? 'active' : ''}
      >
        Eng
      </button>
      <button
        onClick={() => changeLanguage('de')}
        className={currentLanguage === 'de' ? 'active' : ''}
      >
        De
      </button>
      <button
        onClick={() => changeLanguage('bg')}
        className={currentLanguage === 'bg' ? 'active' : ''}
      >
        Bul
      </button>
    </div>
  );
};

export default LanguageSwitcher;