import './App.css';
import React,{useEffect, useState} from 'react';
import './i18n';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import MainContent from './components/MainContent';
import WhoWeAre from './components/WhoWeAre';
import OurProcess from './components/OurProcess';
import WhatWeDo from './components/WhatWeDo';
import OurWork from './components/OurWork';
import JoinOurTeam from './components/JoinOurTeam';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import { logVisit } from './services/apiService';

function App() {

  useEffect(() => {
    logVisit();
  }, []);

  return (
    <div className="App">
      <Navbar />
      <main>
        <MainContent />
        <WhoWeAre />
        <OurProcess />
        <WhatWeDo />
        <OurWork />
        <JoinOurTeam />
        <ContactUs />
        <Footer/>
      </main>
    </div>
  );
}


export default App;
