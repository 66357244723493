const localURL = 'http://artquest-backoffice.local/api';
const realURL = 'https://artquest-backend.eduproject.eu/api';
const testURL = 'http://artquest.local/api'

export const fetchPositions = async (language) => {
    try {
        const response = await fetch(`${realURL}/job-positions?language=${language}`);
        const data = await response.json();
        return data.data;
    } catch (error) {
        //
    }
};

export const fetchWork = async (language) => {
    try {
        const response = await fetch(`${testURL}/all_products?language=${language}`);
        const data = await response.json();
        return data.data;
    } catch (error) {
        //
    }
};

export const logVisit = async () => {
    try {
        const visitResponse = await fetch(`${realURL}/visit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        });
        if (!visitResponse.ok) {
            throw new Error('Could not log visit');
        }
        const visitData = await visitResponse.json();
        return visitData.message;
    } catch (error) {
        return null;
    }
};