import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../css/WhatWeDo.css';
import developCard from '../assets/developCard.png';
import designCard from '../assets/designCard.png';
import conceptualizing from '../assets/conceptualizing.png';
import logoBlack from '../assets/logo-black.png';
import arrowRight from '../assets/Arrow Right.png';
import arrowLeft from '../assets/Arrow Left.png';

const WhatWeDo = () => {
  const { t } = useTranslation();
  const [activeCards, setActiveCards] = useState([]);

  const services = [
    {
      title: t('WhatWeDo.design'),
      description: t('WhatWeDo.designDescription'),
      image: designCard,
      list: [
        t('WhatWeDo.uiux'),
        t('WhatWeDo.concepts'),
        t('WhatWeDo.branding'),
        t('WhatWeDo.apps'),
        t('WhatWeDo.websites'),
      ],
    },
    {
        title: t('WhatWeDo.conceptualizing'),
        description: t('WhatWeDo.conceptualizingDescription'),
        image: conceptualizing,
    },
    {
        title: t('WhatWeDo.develop'),
        description: t('WhatWeDo.developDescription'),
        image: developCard,
    },
  ];

  const toggleActiveCard = (index) => {
    setActiveCards((prevActiveCards) => {
      if (prevActiveCards.includes(index)) {
        return prevActiveCards.filter((cardIndex) => cardIndex !== index);
      } else {
        return [...prevActiveCards, index];
      }
    });
  };

  return (
    <section id="what-we-do" className="what-we-do">
      <h2>{t('WhatWeDo.title')}</h2>
      <div className="service-cards">
        {services.map((service, index) => (
          <div 
            key={index} 
            className={`service-card ${activeCards.includes(index) ? 'active' : ''}`}
            style={activeCards.includes(index) ? { background: 'white' } : { backgroundImage: `url(${service.image})` }}
          >
            <div className="service-card-header">
              <h2>{service.title}</h2>
              <div className="service-card-arrows">
                {activeCards.includes(index) ? (
                  <span onClick={() => toggleActiveCard(index)}><img src={arrowLeft} alt="Arrow left" /></span>
                ) : (
                  <span onClick={() => toggleActiveCard(index)}><img src={arrowRight} alt="Arrow right" /></span>
                )}
              </div>
            </div>
            {activeCards.includes(index) && (
              <>
                <p>{service.description}</p>
                {service.list && (
                  <ul>
                    {service.list.map((item, listIndex) => (
                      <li key={listIndex}>
                        <img src={logoBlack} alt="Logo Icon" />
                        {item}
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};
export default WhatWeDo;