import React from 'react';
import '../css/Modal.css';

const Modal = ({ isOpen, title, content, closeModal }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <h2>{title}</h2>
                    <span onClick={closeModal} className="close-button">&times;</span>
                </div>
                <div dangerouslySetInnerHTML={{ __html: content }} />
                <br />
            </div>
        </div>
    );
};

export default Modal;