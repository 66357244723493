import CookieConsent from "react-cookie-consent";
import { useTranslation } from 'react-i18next';

const CustomCookieConsent = () => {
  const { t } = useTranslation();
  return (
    <CookieConsent
      location="top"
      buttonText= "Ok"
      cookieName="defaultCookie"
      style={{ 
        background: "white",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: "normal",
        fontFamily: "Inter, sans-serif",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color:'black'
      }}
      buttonStyle={{
        color: "#4e503b", 
        fontSize: "14px",
        fontFamily: "Inter, sans-serif",
        alignSelf: "center",
      }}
      expires={750}
      containerClasses="custom-cookie-consent-container"
    >
      {t("cookieConsent")}
    </CookieConsent>
  );
};

export default CustomCookieConsent;