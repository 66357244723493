import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import modelImage from '../assets/model.png';
import { fetchPositions } from '../services/apiService';
import arrowDown from '../assets/arrowdown.png';
import arrowUp from '../assets/arrowup.png';
import '../css/JoinOurTeam.css';

const JoinOurTeam = () => {
  const { t, i18n } = useTranslation();
  const [expanded, setExpanded] = useState(null);
  const [positions, setPositions] = useState([]);

    useEffect(() => {
        const language = i18n.language;
        fetchPositions(language)
            .then(data => setPositions(data))
            .catch(error => console.error('Error fetching positions: ' + error));
    }, [i18n.language]);

  const toggleExpanded = (id) => {
    setExpanded(expanded === id ? null : id);
  };

  const smoothScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  return (
    <section id="join-our-team" className="join-our-team">
    <div className="team-content-wrapper">
        <div className="team-image">
            <img src={modelImage} alt="Model" />
        </div>
        <div className="join-our-team-content">
            <div className="join-our-team-header">
            <h1>{t('JoinOurTeam.title')}</h1>
            <h2>{t('JoinOurTeam.openPositions')}</h2>
            <p>{t('JoinOurTeam.openPositionsDescription')}</p>
            </div>
            {Array.isArray(positions) && positions.length > 0 ? (
            positions.map((position) => (
                <div key={position.id} className={`position ${expanded === position.id ? 'active' : ''}`}>
                <div className="position-header" onClick={() => toggleExpanded(position.id)}>
                    <h3>{position.title}</h3>
                    <img
                    src={expanded === position.id ? arrowUp : arrowDown}
                    alt={expanded === position.id ? 'Collapse' : 'Expand'}
                    className="arrow"
                    />
                </div>
                {expanded === position.id && (
                    <div className="position-description">
                    <p>{position.description}</p>
                      <button className="ContactUsButton" onClick={() => smoothScroll('contact-us-logo')}>
                          {t('JoinOurTeam.contactUs')}
                      </button>
                    </div>
                )}
                </div>
            ))
            ) : (
                <h2 className='no-positions'>{t('no-positions')}</h2>
            )}
        </div>
      </div>
    </section>
  );
};

export default JoinOurTeam;
