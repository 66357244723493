import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchWork } from '../services/apiService';
import logoBlack from '../assets/logo-black.png';
import '../css/OurWork.css';

const OurWork = () => {
  const { t, i18n } = useTranslation();
  const [activeImage, setActiveImage] = useState({ id: null, position: 'right' });
  const [work, setWork] = useState([]);


  useEffect(() => {
    const language = i18n.language;
    fetchWork(language)
      .then(data => setWork(data))
      .catch(error => console.error(error));
  }, [i18n.language]);

  const handleImageClick = (index) => {
    const imageElement = document.querySelector(`#work-item-${index}`);
    if (!imageElement) {
      console.error(`Element #work-item-${index} not found.`);
      return;
    }
    const imageRect = imageElement.getBoundingClientRect();
    const shouldDisplayRight = window.innerWidth - imageRect.right < imageRect.left;
  
    const position = shouldDisplayRight ? 'left' : 'right';
  
    setActiveImage({
      id: index === activeImage.id ? null : index,
      position: position
    });
  };

  return (
    <section id="our-work" className="our-work">
      <h2>{t('OurWork.title')}</h2>
      {work?.length > 0 ? (
        <div className="work-gallery">
          {work.map((item, index) => (
            <div key={index} id={`work-item-${index}`} className="work-item" onClick={() => handleImageClick(index)}>
              <img src={`data:image/png;base64,${item.logo}`} alt={item.title} />
              {activeImage.id === index && (
                <div id={`work-info-${index}`} className={`work-info ${activeImage.position} ${activeImage.show ? '' : 'measure'}`}>
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <h2 className='No-works'>{t('no-works')}</h2>
      )}
    </section>
  );
};

export default OurWork;