import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 
import '../css/Navbar.css';
import LanguageSwitcher from './LanguageSwitcher';
import logo_black from '../assets/logo-black.png';
import logo_white from '../assets/logo.png';
import menu from '../assets/menu.png';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const { t } = useTranslation();

  const smoothScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
    }
    closeMobileMenu();
  };

  return (

    <nav className="navbar">
      <div className="navbar-container">
        <a href="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img src={logo_white} alt="Logo" />
        </a>
        <div className="menu-icon" onClick={handleClick}>
          <img src={menu} alt="Logo" />
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className="nav-item">
            <button className="nav-links" onClick={() => smoothScroll('who-we-are')}>
              {t('WhoWeAre.title')}
            </button>
          </li>
          <li className="nav-item">
            <button className="nav-links" onClick={() => smoothScroll('our-process')}>
              {t('OurProcess.title')}
            </button>
          </li>
          <li className="nav-item">
            <button className="nav-links" onClick={() => smoothScroll('what-we-do')}>
              {t('WhatWeDo.title')}
            </button>
          </li>
          <li className="nav-item">
            <button className="nav-links" onClick={() => smoothScroll('our-work')}>
              {t('OurWork.title')}
            </button>
          </li>
          <li className="nav-item">
            <button className="nav-links" onClick={() => smoothScroll('contact-us-logo')}>
              {t('ContactUs.title')}
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;